import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";

class downloadStickerPage extends Component {
  render() {
    const { locale, t, languageCodeMap } = this.props;
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            class="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/download-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  id="imgCovidAdolescentTopBannerImage"
                  src={load(
                    `./resources/images/${locale}/download-sticker-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container download-container">
          <div className="row">
          <div className="col-md-12 col-sm-12 download-item sticker-set space-for-btn">
              <p id="" className="sticker-item-title">
                {t("75A-sticker download title")}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-01.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-02.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-03.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-04.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-05.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-06.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-07.png`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/75a-2024/75a-the-founding-of-prc-08.png`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/742444n78mk3C/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 download-item sticker-set">
              <p id="" className="sticker-item-title">
                {t("eHealth+ | e+Life ~ e+ Go to Park")}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-1.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-2.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-3.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-4.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-5.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-6.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-7.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-8.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-9.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-10.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-11.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-12.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/elife-school-2024/elife-school-2024-13.gif`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/741246UOiJCvc/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 download-item sticker-set">
              <p id="" className="sticker-item-title">
                {t("eHealth Family sticker packs - Family Daily Life")}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-1.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-2.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-3.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-4.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-5.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-6.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/daily-life-2023/daily-life-2023-7.webp`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/6908036J86N7y/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 download-item sticker-set">
              <p id="" className="sticker-item-title">
                {t("eHealth Family sticker packs - Health Management")}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-1.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-2.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-3.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-4.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-5.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-6.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/health-management-2023/health-management-2023-7.webp`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/690802ATNPDRk/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 download-item space-for-btn sticker-set">
              <p id="" className="sticker-item-title">
                {t("eHealth Family sticker packs - Personal Data Privacy")}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/privacy-2023/privacy-2023-1.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/privacy-2023/privacy-2023-2.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/privacy-2023/privacy-2023-3.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/privacy-2023/privacy-2023-4.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/privacy-2023/privacy-2023-5.webp`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/privacy-2023/privacy-2023-6.webp`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/690801UB9c9kL/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 download-item space-for-btn sticker-set">
              <p id="" className="sticker-item-title">
                {t("eHealth Family sticker packs - Chinese New Year 2023")}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/cny-set-2023/cny-set-2023-1.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/cny-set-2023/cny-set-2023-2.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/cny-set-2023/cny-set-2023-3.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/cny-set-2023/cny-set-2023-4.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/cny-set-2023/cny-set-2023-5.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/cny-set-2023/cny-set-2023-6.gif`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/6462782ToSMhI/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 download-item space-for-btn sticker-set">
              <p id="" className="sticker-item-title">
                {t(
                  "eHealth Family sticker packs – Merry Christmas and Happy New Year 2023"
                )}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/xmas-set-2022/xmas-set-2022-1.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/xmas-set-2022/xmas-set-2022-2.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/xmas-set-2022/xmas-set-2022-3.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/xmas-set-2022/xmas-set-2022-4.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/xmas-set-2022/xmas-set-2022-5.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/xmas-set-2022/xmas-set-2022-6.gif`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/6394302tnQRq1/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 download-item sticker-set">
              <p id="" className="sticker-item-title">
                {t(
                  "eHealth Family sticker packs - Blood Pressure and Blood Sugar Buddy"
                )}
              </p>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-1.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-2.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-3.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-4.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-5.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-6.gif`
                  )}
                />
              </div>
              <div className="sticker-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/bpbg-set-2022/bpbg-2022-7.gif`
                  )}
                />
              </div>
              <div className="sticker-item-download">
                <a
                  href="https://whatsticker.online/p/524831YEeP7IB/HK/zh"
                  target="_blank"
                  className="item-download"
                >
                  {t("Download Now")}
                </a>
              </div>
            </div>
          </div>

          <div className="promulgation-back">
            <a href="/promulgation">{t("Back")}</a>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(downloadStickerPage);
