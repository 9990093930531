import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import BannerSlider from "./BannerSlider";
import Swiper from "./Swiper";

class PromotionTruck extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`promulgation-promotion-truck-page page-container page-container-${locale}`}
        id="MainContent"
      >
        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          className="static_page_header"
        >
          <div className="container-full">
            <div className="row vertical-align">
              <div className="col-sm-12">
                <img
                  id="imgCovidQRTopBannerImage"
                  data-aos-anchor-placement="center-center"
                  src={load(`./resources/images/${locale}/truck-main-img.jpg`)}
                  alt=" "
                />
                {/* <img id="imgCovidQRTopBannerImage" className="display-mobile" data-aos-anchor-placement="center-center" src={load(`./resources/images/truck-main-img-mobile.jpg`)} alt=" "/> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container content-title-container">
            <div
              className="content-title promulgation-page-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <img
                className="content-title-icon"
                src={load(
                  `./resources/images/promulgation/landing/promulgation-landing-promo-truck-icon.png`
                )}
                alt=""
              />
              <span className="content-title-text">
                {t("Promotion Truck title")}
              </span>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container introduction-text-container">
            <div className="row vertical-align">
              <div
                data-aos="fade"
                data-aos-delay="400"
                data-aos-offset="-200"
                className="col-md-12 col-sm-12"
              >
                <div id="" className="promulgation-item-intro">
                  {t("promulgation-truck-intro")}
                </div>
                <div className="text-highlight">
                  {t("promulgation-truck-highlight")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid-full truck-slider-container">
          <div
            className="container-full"
            data-aos="fade"
            data-aos-delay="200"
            data-aos-offset="-200"
          >
            <Swiper {...this.props} />
          </div>
        </div>

        {/* <div className="container-fluid">
                    <div className="container">
                        <BannerSlider {...this.props} />
                    </div>
                </div> */}

        <div className="container-fluid">
          <div className="container content-title-container">
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <span className="content-title-text">{t("Event Photos")}</span>
            </div>
          </div>
        </div>

        <div className="container-fluid truck-event-photos">
          <div className="container">
            <div
              className="row vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-md-6 col-sm-12">
                <a
                  target="_blank"
                  href={load(
                    `./resources/images/promulgation/promotional-truck/truck-photo-01.jpg`
                  )}
                >
                  <img
                    src={load(
                      `./resources/images/promulgation/promotional-truck/truck-photo-01.jpg`
                    )}
                    alt=""
                  />
                </a>
              </div>
              <div className="col-md-6 col-sm-12">
                <a
                  target="_blank"
                  href={load(
                    `./resources/images/promulgation/promotional-truck/truck-photo-02.jpg`
                  )}
                >
                  <img
                    src={load(
                      `./resources/images/promulgation/promotional-truck/truck-photo-02.jpg`
                    )}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div
              className="row vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-md-6 col-sm-12">
                <a
                  target="_blank"
                  href={load(
                    `./resources/images/promulgation/promotional-truck/truck-photo-03.jpg`
                  )}
                >
                  <img
                    src={load(
                      `./resources/images/promulgation/promotional-truck/truck-photo-03.jpg`
                    )}
                    alt=""
                  />
                </a>
              </div>
              <div className="col-md-6 col-sm-12">
                <a
                  target="_blank"
                  href={load(
                    `./resources/images/promulgation/promotional-truck/truck-photo-04.jpg`
                  )}
                >
                  <img
                    src={load(
                      `./resources/images/promulgation/promotional-truck/truck-photo-04.jpg`
                    )}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div
              className="row vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-md-6 col-sm-12">
                <a
                  target="_blank"
                  href={load(
                    `./resources/images/promulgation/promotional-truck/truck-photo-05.jpg`
                  )}
                >
                  <img
                    src={load(
                      `./resources/images/promulgation/promotional-truck/truck-photo-05.jpg`
                    )}
                    alt=""
                  />
                </a>
              </div>
              <div className="col-md-6 col-sm-12">
                <a
                  target="_blank"
                  href={load(
                    `./resources/images/promulgation/promotional-truck/truck-photo-06.jpg`
                  )}
                >
                  <img
                    src={load(
                      `./resources/images/promulgation/promotional-truck/truck-photo-06.jpg`
                    )}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="promulgation-back"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-offset="-200"
        >
          <a href="/promulgation">{t("Back")}</a>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PromotionTruck);
