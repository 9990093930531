import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import "../../../resources/styles/elifeCoins.scss";

class LifeCoins extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={` page-container page-container-${locale} page-elife-coins`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/elife/${locale}/life-earn-point-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/elife/${locale}/life-earn-point-banner-mobile.jpg`
            )}
          />
        </div>

        <div className="container life-earnpoint-table-container">
          <div className="row elife-box-content vertical-align">
            <div className="col-md-6">
              <h5>{t("earn coins intro sub title")}</h5>
              <h3>{t("earn coins intro title")}</h3>
              <h5>{t("earn coins intro content")}</h5>
            </div>
            <div className="col-md-6 life-tutorial-phone">
              <img src={load(`./resources/images/elife/coins-intro.png`)} />
            </div>
          </div>

          <div className="row trial-launch-container">
            <div className="col-sm-12">{t("trial launch notice")}</div>
          </div>

          <div
            ref={this.youtubeContainer}
            className="second-youtube-container overview-video"
          >
            <iframe
              id="videoChildGrowth"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/ZnWvYKEBGIQ"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>

          <div className="empty-space-40"></div>
          <div className="col-lg-12 col-sm-12 desktop-show earn-point-table-container ">
            <h3>{t("Collectible Character / Health Gadgets")}</h3>
            <div class="point-remarks">
              <b>{t("Daily Max remarks title")}</b>
              {t("Daily Max remarks small text")}
            </div>
            <table className="earn-point-table">
              <tr>
                <th width="120px">{t("Tier")}</th>
                <th>{t("Characters")}</th>
                <th>{t("Tier up conditions")}</th>
              </tr>
              <tr>
                <td>{t("Tier 1 (2 pts)")}</td>
                <td width="">
                  <img
                    src={load(`./resources/images/elife/elife-coins-tier3.png`)}
                  />
                </td>
                <td>{t("Collect 10 tier 1 characters (repeatable)")}</td>
              </tr>
              <tr>
                <td>{t("Tier 2 (5 pts)")}</td>
                <td width="">
                  <img
                    src={load(`./resources/images/elife/elife-coins-tier2.png`)}
                  />
                </td>
                <td>{t("Collect 10 tier 1 or 2 characters (repeatable)")}</td>
              </tr>
              <tr>
                <td>{t("Tier 3 (10 pts)")}</td>
                <td width="">
                  <img
                    src={load(`./resources/images/elife/elife-coins-tier1.png`)}
                  />
                </td>
                <td>
                  {t("Congratulations! You have reached the highest tier")}
                </td>
              </tr>
            </table>
            <div className="row coins-gadget-table vertical-align">
              <div className="col-md-5 col-sm-12">
                <img
                  src={load(
                    `./resources/images/elife/elife-coins-gadgets-item.png`
                  )}
                />
              </div>
              <div className="col-md-7 col-sm-12">{t("gadget table des")}</div>
            </div>
            <div className="empty-space-40"></div>
          </div>
          <div className="col-lg-12 col-sm-12 mobile-show">
            <h3>{t("Collectible Character / Health Gadgets")}</h3>
            <div class="point-remarks">
              <b>{t("Daily Max remarks title")}</b>
              {t("Daily Max remarks small text")}
            </div>
            <div className="mobile-collectible-box">
              <div className="collectible-Tier">{t("Tier 1 (2 pts)")}</div>
              <div className="collectible-img">
                <img
                  src={load(`./resources/images/elife/elife-coins-tier3.png`)}
                />
              </div>
              <div className="collectible-lv-up-conditions">
                {t("Collect 10 tier 1 characters (repeatable)")}
              </div>
            </div>
            <div className="mobile-collectible-box">
              <div className="collectible-Tier">{t("Tier 2 (5 pts)")}</div>
              <div className="collectible-img">
                <img
                  src={load(`./resources/images/elife/elife-coins-tier2.png`)}
                />
              </div>
              <div className="collectible-lv-up-conditions">
                {t("Collect 10 tier 1 or 2 characters (repeatable)")}
              </div>
            </div>
            <div className="mobile-collectible-box">
              <div className="collectible-Tier">{t("Tier 3 (10 pts)")}</div>
              <div className="collectible-img">
                <img
                  src={load(`./resources/images/elife/elife-coins-tier1.png`)}
                />
              </div>
              <div className="collectible-lv-up-conditions">
                {t("Congratulations! You have reached the highest tier")}
              </div>
            </div>
            <div className="row coins-gadget-table vertical-align">
              <div className="col-md-5 col-sm-12">
                <img
                  src={load(
                    `./resources/images/elife/elife-coins-gadgets-item.png`
                  )}
                />
              </div>
              <div className="col-md-7 col-sm-12">{t("gadget table des")}</div>
            </div>
            <div className="empty-space-40"></div>
          </div>
          <div className="col-lg-6 col-sm-12 ">
            <h3>{t("Daily Missions")}</h3>
            <div className="mission-des">{t("Daily Mission Description")}</div>
            <table className="earn-point-table">
              <tr>
                <th>{t("Daily Mission")}</th>
                <th width="100px">{t("Coins")}</th>
              </tr>
              <tr>
                <td>{t("Daily Game Play")}</td>
                <td>5</td>
              </tr>
              <tr>
                <td>{t("Collect any one character")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect any one gadget")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect any 10 characters or gadgets")}</td>
                <td>25</td>
              </tr>
            </table>
          </div>

          <div className="col-lg-6 col-sm-12 ">
            <h3>{t("Main Missions")}</h3>
            <div className="mission-des">{t("Main Mission Description")}</div>
            <table className="earn-point-table">
              <tr>
                <th>{t("Main Mission")}</th>
                <th width="100px">{t("Coins")}</th>
              </tr>
              <tr>
                <td>{t("Completed the Pre-game Survey")}</td>
                <td>20</td>
              </tr>
              <tr>
                <td>{t("Completed the Post-game Survey")}</td>
                <td>30</td>
              </tr>
              <tr>
                <td>{t("Collect 5, 10, 20, 30, 40, 50, 60 characters")}</td>
                <td>20</td>
              </tr>
              <tr>
                <td>
                  {t(
                    "Collect 50, 75, 100, 150, 200, 250, 300 characters or gadgets"
                  )}
                </td>
                <td>20</td>
              </tr>
              <tr>
                <td>{t("Complete energy collection in each park")}</td>
                <td>20</td>
              </tr>
              <tr>
                <td>{t("Collect all tiers characters")}</td>
                <td>15</td>
              </tr>
              <tr>
                <td>{t("Collect all first-tier characters")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect all second-tier characters")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect all third-tier characters")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>
                  {t(
                    "Continue play game 3, 7, 14, 21, 30, 60, 90 days,and catch 1 character or gadgets"
                  )}
                </td>
                <td>10</td>
              </tr>
              <tr>
                <td>
                  {t("Visit a new park, and collect any character or gadget")}
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>{t("Collect all gadgets")}</td>
                <td>20</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeCoins);
