import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";

class EppPrivacyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectioneHealth: ["close"],
      sectioneLife: ["close"],
    };
  }
  toggleQuestion(section, index) {
    const newSectionState = [...this.state[section]];
    if (newSectionState[index] === "close") {
      newSectionState[index] = "open";
    } else {
      newSectionState[index] = "close";
    }
    let openCount = 0;
    let isOpenAllInSection = null;
    for (let section of newSectionState) {
      if (section === "open") openCount += 1;
    }
    if (openCount === newSectionState.length) isOpenAllInSection = true;
    else if (openCount === 0) isOpenAllInSection = false;

    if (isOpenAllInSection !== null) {
      this.setState({
        ...this.state,
        [section]: newSectionState,
        [`${section}OpenAll`]: isOpenAllInSection,
      });
    } else {
      this.setState({
        ...this.state,
        [section]: newSectionState,
      });
    }
  }

  render() {
    const { sectioneHealth, sectioneLife } = this.state;
    const { locale, t } = this.props;
    let { languageCodeMap } = this.props;
    let ehealthPrivacyPolicyURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]["code"]}/privacy-policy/index.html`;
    let ehealthLifePrivacyPolicyURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]["code"]}/you-and-your-family/support/privacy-policy-statement.html`;
    let personalInfoCollectURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]["code"]}/you-and-your-family/support/hcr-personal-information-collection-statement.html`;

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="empty-space-120"></div>

        <div className="epp-privacy-background">
          <div className="epp-privacy-container">
            <div id="eppPrivacyTitle" class="epp-privacy-title">
              {t("EPP Privacy Title 1")}
            </div>
            <div className="faq-section">
              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectioneHealth", 0)}
                >
                  {t("醫健通 eHealth App")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectioneHealth[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectioneHealth[0] === "open" && (
                  <div className="faq-answer">
                    <div id="eppPrivacyContent" className="epp-privacy-content">
                      <h3 id="eppPrivacyTitle" class="privacy-subtitle">
                        {t("EPP Privacy Title 1")}
                      </h3>
                      <p>{t("EPP Privacy Content 1")}</p>
                      <p>{t("EPP Privacy Content 2")}</p>
                      <p>
                        {t("EPP Privacy Content 3")}
                        <a
                          id="ehealthPrivacyPolicyURL"
                          href={ehealthPrivacyPolicyURL}
                          target="_blank"
                          role="text"
                          tabIndex="0"
                          aria-label={ehealthPrivacyPolicyURL}
                        >
                          {ehealthPrivacyPolicyURL}
                        </a>
                      </p>
                      <p>{t("EPP Privacy Content 4")}</p>
                      <p>{t("EPP Privacy Content 5")}</p>
                      <p>{t("EPP Privacy Content 6")}</p>
                      <p>{t("EPP Privacy Content 7")}</p>
                      <p>{t("EPP Privacy Content 8")}</p>
                      <p>{t("EPP Privacy Content 9")}</p>
                      <p>{t("EPP Privacy Content 10")}</p>
                      <p>{t("EPP Privacy Content 11")}</p>
                      <p>{t("EPP Privacy Content 12")}</p>
                      <p>{t("EPP Privacy Content 13")}</p>
                      <p>{t("EPP Privacy Content 14")}</p>
                    </div>

                    <h3 id="eppPrivacyTitle2" class="privacy-subtitle">
                      {t("EPP Privacy Title 2")}
                    </h3>
                    <div
                      id="eppPrivacyContent2"
                      className="epp-privacy-content"
                    >
                      <p>{t("EPP Privacy Content 15")}</p>
                      <p>{t("EPP Privacy Content 16")}</p>
                      <p>{t("EPP Privacy Content 17")}</p>
                    </div>

                    <h3 id="eppPrivacyTitle3" class="privacy-subtitle">
                      {t("EPP Privacy Title 3")}
                    </h3>
                    <div
                      id="eppPrivacyContent3"
                      className="epp-privacy-content"
                    >
                      <p>{t("EPP Privacy Content 18")}</p>
                    </div>
                    <h3 id="eppPrivacyTitle4" class="privacy-subtitle">
                      {t("EPP Privacy Title 4")}
                    </h3>
                    <div
                      id="eppPrivacyContent4"
                      className="epp-privacy-content"
                    >
                      <p>
                        {t("EPP Privacy Content 19")}
                        <a
                          id="personalInfoCollectURL"
                          href={personalInfoCollectURL}
                          target="_blank"
                          role="text"
                          tabIndex="0"
                          aria-label={personalInfoCollectURL}
                        >
                          {personalInfoCollectURL}
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectioneLife", 0)}
                >
                  {t("e+Life App")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectioneLife[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectioneLife[0] === "open" && (
                  <div className="faq-answer">
                    <div id="eppPrivacyContent" className="epp-privacy-content">
                      <h3 class="privacy-subtitle">
                        {t("Life Privacy Section 1 Title")}
                      </h3>
                      <p>{t("Life Privacy Content 1")}</p>
                      <p>{t("Life Privacy Content 2")}</p>
                      <p>
                        {t("Life Privacy Content 3")}
                        <a
                          id="ehealthLifePrivacyPolicyURL"
                          href={ehealthLifePrivacyPolicyURL}
                          target="_blank"
                          role="text"
                          tabIndex="0"
                          aria-label={ehealthLifePrivacyPolicyURL}
                        >
                          {ehealthLifePrivacyPolicyURL}
                        </a>
                      </p>
                      <p>{t("Life Privacy Content 4")}</p>
                      <p>{t("Life Privacy Content 5")}</p>
                      <p>{t("Life Privacy Content 6")}</p>
                      <p>{t("Life Privacy Content 7")}</p>
                      <p>{t("Life Privacy Content 8")}</p>
                      <p>{t("Life Privacy Content 9")}</p>
                      <p>{t("Life Privacy Content 10")}</p>
                      <h3 class="privacy-subtitle">
                        {t("Life Privacy Section 2 Title")}
                      </h3>
                      <p>{t("Life Privacy Content 11")}</p>
                      <p>{t("Life Privacy Content 12")}</p>
                      <p class="privacy-subpoint">
                        {t("Life Privacy Content 12a")}
                      </p>
                      <p class="privacy-subpoint">
                        {t("Life Privacy Content 12b")}
                      </p>
                      <p class="privacy-subpoint">
                        {t("Life Privacy Content 12c")}
                      </p>
                      <h3 class="privacy-subtitle">
                        {t("Life Privacy Section 3 Title")}
                      </h3>
                      <p>{t("Life Privacy Content 13")}</p>
                      <p>{t("Life Privacy Content 14")}</p>
                      <p>{t("Life Privacy Content 15")}</p>
                      <h3 class="privacy-subtitle">
                        {t("Life Privacy Section 4 Title")}
                      </h3>
                      <p>{t("Life Privacy Content 16")}</p>
                      <h3 class="privacy-subtitle">
                        {t("Life Privacy Section 5 Title")}
                      </h3>
                      <p>
                        {t("Life Privacy Content 17")}
                        <a
                          id="personalInfoCollectURL"
                          href={personalInfoCollectURL}
                          target="_blank"
                          role="text"
                          tabIndex="0"
                          aria-label={personalInfoCollectURL}
                        >
                          {personalInfoCollectURL}
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(EppPrivacyPage);
