import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";

class LifeTutorial extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/us/app/e-life/id6502993463",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life",
    huaweiDownloadURL: "https://appgallery.huawei.com/app/C110183237",
  };
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }
  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }

  constructor(props) {
    super(props);
    this.state = {
      tab: "",
      tabs: [
        {
          key: "above",
          name: "16 years old or above",
        },
        {
          key: "under",
          name: "Under 16 years old",
        },
      ],
    };
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
  }

  handleSwitchTab(tab) {
    this.setState({
      tab,
    });
  }

  componentDidMount() {
    this.setState({
      tab: this.state.tabs[0].key,
    });
  }

  render() {
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    const { locale, t } = this.props;
    let {
      languageCodeMap,
      wcag22aURL,
      appStoreDownloadURL,
      googelPlayDownloadURL,
      huaweiDownloadURL,
    } = this.props;
    let { registerLink } = this.props;
    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/register-online.html";
    }

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/us/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/cn/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/hk/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    }

    return (
      <div
        className={` page-container page-container-${locale} life-tutorial-page`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/life-faq-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  src={load(
                    `./resources/images/elife/${locale}/life-tutorial-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <div className="container-fluid ">
          <div className="container">
            <h2 className="life-section-title">
              {t("Download Tutorial Title")}
            </h2>

            <div className="row elife-box-content vertical-align">
              <div className="col-md-4 life-tutorial-phone">
                <img
                  src={load(
                    `./resources/images/elife/${locale}/life-tutorial-download-phone.jpg`
                  )}
                />
              </div>
              <div className="col-md-8 life-tutorial-phone-intro">
                <h3>{t("Download Tutorial Sub Title")}</h3>
                {/* <div class="release-date-reminder">
                  {t("release date reminder")}
                </div> */}
                <h5>{t("Download Tutorial Content")}</h5>
                <div className="col-md-4 tutorial-qr-col">
                  <img
                    className="tutorial-qr"
                    src={load(
                      `./resources/images/elife/life-tutorial-download-qr.jpg`
                    )}
                  />
                </div>
                <div className="col-md-8 row life-download-btn">
                  <div className="col-xs-6 col-sm-6">
                    <a
                      id="btnDownloadAppStore"
                      href={appStoreDownloadURL}
                      rel="noopener noreferrer"
                      className="second-download-now-href"
                      target="_blank"
                      tabIndex="0"
                    >
                      <img
                        id="imgBtnDownloadAppStore"
                        src={load(`./resources/images/${locale}/app_store.png`)}
                        alt={t("Download at App Store")}
                      />
                    </a>
                  </div>
                  <div className="col-xs-6 col-sm-6">
                    <a
                      id="btnDownloadGooglePlay"
                      href={googelPlayDownloadURL}
                      rel="noopener noreferrer"
                      className="second-download-now-href"
                      target="_blank"
                      tabIndex="0"
                    >
                      <img
                        id="imgBtnDownloadGooglePlay"
                        src={load(
                          `./resources/images/${locale}/google_play.png`
                        )}
                        alt={t("Download at Google Play")}
                      />
                    </a>
                  </div>
                  <div className="col-xs-6 col-sm-6">
                    <a
                      id="btnDownloadHuawei"
                      href={huaweiDownloadURL}
                      rel="noopener noreferrer"
                      className="second-download-now-href"
                      target="_blank"
                      tabIndex="0"
                    >
                      <img
                        id="imgBtnDownloadHuawei"
                        src={load(`./resources/images/${locale}/huawei.png`)}
                        alt={t("Download at Huawei App Gallery")}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container how-to-use-elife">
            <h2 className="life-section-title">
              {t("How to log in and use the e+Life")}
            </h2>

            <div className="row elife-box-content ">
              <div className="">
                {t("How to log in and use the e+Life content 1")}
                <a href={registerLink} target="_blank">
                  {t("How to log in and use the e+Life link label 1")}
                </a>
                {t("How to log in and use the e+Life content 2")}
                <a
                  href="https://app.ehealth.gov.hk/redirection.html"
                  target="_blank"
                >
                  {t("How to log in and use the e+Life link label 2")}
                </a>{" "}
                <br />
                {t("How to log in and use the e+Life content 3")}
              </div>

              <div className="col-12 tabs-row">
                <div class="tabs">
                  {this.state.tabs?.map((tab) => {
                    return (
                      <div
                        className={`tab ${
                          this.state.tab === tab.key ? "active" : ""
                        }`}
                        onClick={() => {
                          this.handleSwitchTab(tab.key);
                        }}
                      >
                        {t(tab.name)}
                      </div>
                    );
                  })}
                </div>

                <div className="tab-content">
                  <div
                    className={`${this.state.tab === "above" ? "active" : ""}`}
                  >
                    <div
                      ref={this.youtubeContainer}
                      className="second-youtube-container"
                    >
                      <iframe
                        id="videoChildGrowth"
                        key={locale}
                        className="second-responsive-iframe"
                        title={t("Video des")}
                        src="https://www.youtube.com/embed/6uP13SADYkk"
                        alt={t("Video")}
                        tabIndex="0"
                      ></iframe>
                    </div>
                  </div>

                  <div
                    className={`${this.state.tab === "under" ? "active" : ""}`}
                  >
                    <div
                      ref={this.youtubeContainer}
                      className="second-youtube-container"
                    >
                      <iframe
                        id="videoChildGrowth"
                        key={locale}
                        className="second-responsive-iframe"
                        title={t("Video des")}
                        src="https://www.youtube.com/embed/HFqflv0491c"
                        alt={t("Video")}
                        tabIndex="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="empty-space-100"></div>

          <div className="container"></div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeTutorial);
